@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  scroll-behavior: smooth;
}

body {
  font-family: WorkSans;
}

h1, h2, h3, h4, h5 {
  font-family: Forum;
}

.imageViewer > .container {
    max-width: inherit!important;
}

@layer base {
    h5 {
        @apply text-3xl lg:text-4xl mt-4 mb-2;
    }
  @font-face {
    font-family: Forum;
    font-weight: 400;
    src: url(./fonts/Forum-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: WorkSans;
    font-weight: 400;
    src: url(./fonts/WorkSans-Regular.ttf) format("truetype");
  }
}
